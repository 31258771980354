import React from 'react'
import { useState, useCallback } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import EZShipBoxSideForm, { EZShipBoxBackdrop } from '../components/repairs/EZShipBoxSideForm'
import CustomerRepairLetters from '../components/repairs/CustomerRepairLetters'
import OurExperience from '../components/repairs/OurExperience'
//import CategoryLink from '../components/CategoryLink'
import YouTube from '../components/homepage/YouTube'

const JewelryRepair = () => {
  const [open, setOpen] = useState(false)
  const toggleForm = useCallback(() => setOpen(o => !o), [setOpen])

  return (
    <Layout className={'mx-auto'} canonical="/jewelry-repair/">
      <SEO title="Miami Beach Jewelry Repair Experts"
        description={
          'Jewelry repair in Miami FL & South Florida is best served by Gray & Sons Jewelers. Rings, chains, stones, diamond setting, refinish, polish & repair.'
        }
        canonical="/jewelry-repair/"
      />
      <section>
        <header className="grid w-full bg-gray-200 ">
          <div className="container mx-auto flex flex-col items-center justify-center lg:flex-row">
            <div className="relative w-full">
              <div>
                <StaticImage
                  alt="Gray and Sons Jewelers Header"
                  src={'../images/1600-gns-site-banner-00.png'}
                  loading="eager"
                />
              </div>
            </div>
            <div className="flex w-full flex-col items-center p-5 text-center">
              <h1 className="banner-h2-grow font-bold text-black uppercase leading-10">
                Jewelry Repairs!
                <br />
                <span className="text-xl md:text-3xl">Size a Ring, Fix a Bracelet, Polish...</span>
              </h1>
              <h3 className="banner-h3-grow">
                Since 1980, Gray and Sons has been dominating repairs within the luxury watch and
                jewelry industry. We specialize in all types of repairs and make the impossible
                feasible. The repairs are processed and completed in our boutique by our in-house
                master watchmakers and jewelers. Our in-house jewelers work on all sorts of jewelry
                including rings, necklaces, earrings, bracelets, and more! Gray and Sons will handle
                all types of repairs, even minor adjustments.
              </h3>
              <button className="gns-button" onClick={toggleForm}>
                QUOTE MY REPAIR
              </button>
            </div>
          </div>
        </header>
      </section>

      <div className=" flex w-full justify-center">
        <div className="flex flex-col justify-center lg:flex-row ">
          <EZShipBoxSideForm open={open} toggleForm={toggleForm} />
          <section className="order-1 mx-auto w-full max-w-[1366px] px-5">
            <div className="flex flex-col items-center">
              <h2 className="underline-title my-5 ">LUXURY WATCH BRANDS WE REPAIR</h2>
              <p className="w-full max-w-[800px] mx-auto leading-loose">
                Anything beyond the stretch of your imagination within the jewelry repair business,
                Gray and Sons will tackle your issue! If you have any questions, feel free to ask;
                we can easily break down the process and steps for the customer's comprehension. If
                you love the ring your boyfriend gifted you but cannot stand yellow gold and wished
                it would've been white gold, Gray and Sons can take care of you. If your bracelet
                doesn’t fit quite right, we can lengthen or shorten it. Accidents happen; if your
                necklace gets caught on the sweater Grandma knitted for you and diamonds fall out…
                we can replace the diamond for you! Imagine you inherited a two carat diamond ring
                but it looks too old-fashioned for you; let Gray & Sons transfer the diamond to a
                more modern setting from our vast selection. Jewelry is a way to express yourself,
                and it is often inexpensive to repair or refine a piece to pristine condition.
              </p>
              <Link
                to={'/jewelry-repair'}
                onClick={toggleForm}
                className="mt-10 block border-2  rounded-lg border-red-700 mx-auto max-w-max text-center text-red-700  px-4 py-2 text-xl hover:text-white hover:bg-red-700 hover:-translate-y-2 hover:shadow-md duration-150"
              >
                <h3>
                  <b>NEED JEWELRY REPAIR?</b>
                  <br />
                  CLICK HERE TO BEGIN THE PROCESS
                </h3>
              </Link>
            </div>
            <p className="w-full max-w-[800px] mx-auto py-10 leading-loose">
              Not only will our jewelry repair department fix your issue, but we will also enhance
              the appearance of your piece. Thanks to our in-house polisher, we can make that
              possible. Our polisher delivers excellence to the table and is trained to never
              over-polish any pieces. You never want your watch or jewelry to be over-polished
              because it removes an excessive amount of gold, platinum, or silver. Our jewelry
              repair shop department not only fixes what is requested, we check the entire piece to
              ensure there are no potential future problems. We look for loose stones, weak spots,
              and give our best advice for your safety and enjoyment of your jewelry. Gray and Sons
              specializes in watch repair in addition to our jewelry repair department. In fact,
              often times both departments collaborate to produce the best result. An example is the
              "Detail" feature in our watch repairs. When you receive your watch repair after
              completion, you will notice that your luxury Swiss watch is in immaculate condition. A
              bonus when buying merchandise from our store is that we include a "Like New For Life"
              bonus which means that you can annually send your watch to us for free cleaning from
              our polisher. In regards to watches, the "Like New For Life" policy includes us
              maintaining the water-resistance of your timepiece (if originally water resistant).
              Give us a call or send us an email for further inquiries.
            </p>
            <OurExperience />
            <section className="grid grid-cols-2 xl:grid-cols-4 gap-5 uppercase">
              <h2 className="col-span-2 xl:col-span-4 underline-title my-5">
                Jewelry Repair Services
              </h2>
              <div>
                <StaticImage
                  alt="Ring Repair"
                  src={'../images/ring-repair.jpeg'}
                  loading="lazy"
                  className="w-full md:aspect-square"
                />
                <h3>Ring Repair</h3>
              </div>
              <div>
                <StaticImage
                  alt="Ring Resizing"
                  src={'../images/ring-resizing.webp'}
                  loading="lazy"
                  className="w-full md:aspect-square"
                />
                <h3>Ring Resizing</h3>
              </div>
              <div>
                <StaticImage
                  alt="Ring and Jewelry Stone Setting"
                  src={'../images/stone-seting.webp'}
                  loading="lazy"
                  className="w-full md:aspect-square"
                />
                <h3>Ring Repair</h3>
              </div>
              <div>
                <StaticImage
                  alt="Bracelet Refinish and Jewelry Polishing"
                  src={'../images/watch-bracelet-refinish.webp'}
                  loading="lazy"
                  className="w-full md:aspect-square"
                />
                <h3>Bracelet Refinish</h3>
              </div>
              <div className={'button-secondary'}>SOLDERING</div>
              <Link className={'button-secondary'} to="/designing-custom-money-clip-jewelry-repair">
                CUSTOMIZING
              </Link>
              <div className={'button-secondary'}>STONE REPLACEMENT</div>
              <div className={'button-secondary'}>TRADITIONAL METHODS</div>
              <div className={'button-secondary'}>MODERN METHODS</div>
              <Link className={'button-secondary'} to="/watch-buckle-repair">
                RECONDITIONING
              </Link>
              <div className={'button-secondary'}>TRANSFORMING</div>
              <Link className={'button-secondary'} to="/repairing-your-heart">
                RESTORING
              </Link>
            </section>

            <section className="my-20">
              <h2 className="text-xl font-bold text-center my-5 w-full max-w-[800px] mx-auto">
                Jewelry is in our name and is the foundation of our founding since Gray and Sons
                opened its doors in the year 1980.
              </h2>
              <p className="w-full max-w-[800px] mx-auto">
                Our highly skilled in-house jewelry repair team along with 5 Master trained
                watchmakers who collaborate daily on trusted solutions to deliver the best quality
                results. A recent client of ours came to us with an 18k yellow gold ring with
                tanzanite gemstone. The gemstone was loose due to the weak setting of the ring. The
                entire piece was hollow and began to crack in different areas, causing some
                instability within the ring. We gathered our thoughts and offered to make an
                entirely new ring. Bigger, heavier and more robust to ensure the security of the
                tanzanite.
                <br />
                <br />
                To begin with the jewelry repair, we started by taking measurements of the ring to
                accurately hand carve the wax that will serve as the mold for the ring. The original
                ring carried approximately 6 DWT of gold. After some adjustments, the new ring will
                be approximately 16 DWRT of gold, nearly 10 pennyweight of difference. The increase
                will allow for a more stable and secure ring for the gemstone. Since the tanzanite
                was out of the setting, we took advantage to have it polished carefully to enhance
                the appearance of the ring. Once the wax is carved to specification, the mold is
                sent to be cast in virgin 18k yellow gold and the jewlery repair has begun.
              </p>
              <div className="py-10 w-full">
                <StaticImage
                  alt="mens gold ring repair and soldering"
                  src={'../images/gold-ring-repair.webp'}
                  loading="lazy"
                  className="w-full"
                />
              </div>
              <h2 className="text-xl font-bold text-center my-5 w-full max-w-[800px] mx-auto">
                All your jewlery repair needs can be resolved with our jewlery experts. Any jewelry
                restoration, modification, or maintenance on all fine jewlery.
              </h2>
              <p className="w-full max-w-[800px] mx-auto leading-loose">
                Once casted, the ring returns to our master jewelry repair team - who combined has
                over 40 years of experience repairing jewlery of all types. The ring is then hand
                filed to remove any burrs and begin smoothing out the ring. A center square inside
                the setting is cut to allow the culet of the tanzanite to rest securely. The purpose
                of this portion of the jewelry repair is so to have light reflect through the
                tanzanite for clarity as well as to allow for cleaning of build up around the stone.
                <br />
                <br />
                This process is very meticulous and requires patients and good dexterity. The ring
                is then polished to bring out the luster of the ring and prepared for the setting of
                the stone. The tanzanite is squared with a bit of a curve, so the jeweler must file
                the setting precisely to ensure a safe housing for the gemstone. Once set, the
                jeweler gives the ring a light pass on the polish wheel to give it one last shine.
                <br />
                <br />
                The client was overly pleased with the result of his new ring. The new ring provided
                a sense of security with its more robust body. The stone popped more with the
                polishing it was given and stood out from the ring to give it a great contrast.
              </p>
              <p className="py-5 w-full max-w-[800px] mx-auto leading-loose">
                We accept bank wire, Zelle, ACH and personal checks. Personal checks must be cleared
                before shipping.
              </p>
              <div className="py-10 w-full">
                <StaticImage
                  alt="mens gold ring stone setting"
                  src={'../images/gold-ring-repair2.webp'}
                  loading="lazy"
                  className="w-full"
                />
              </div>
            </section>
            <YouTube
              src="https://www.youtube.com/embed/gHqod-LEvvc"
              title="Gray & Sons The Art of Jewelry: Bvlgari Celtaura Necklace Extension"
              placeholder="../images/jewelry-repair-thumbnail-video.jpeg"
              className="w-10/12 max-w-[800px] mx-auto aspect-video"
            />
          </section>
        </div>
      </div>
      <div className="mx-auto my-20 w-full max-w-[1366px] px-12">
        <CustomerRepairLetters />
      </div>
      <EZShipBoxBackdrop open={open} toggleForm={toggleForm} />
    </Layout>
  )
}

export default JewelryRepair
